import { IApolloClientProps } from "./graphql/ApolloBaseClient";
import { createMemberClient, MemberClient } from "./graphql/MemberClient";
import { createGuestClient, GuestClient } from "./graphql/GuestClient";
import {
  ICompleteSignUpProcessRequestInput,
  IUpdateUserProfileRequestInput,
  IGetProfilePointActivityInput,
  IGetMemberTripHistoryInput,
  ISignUpInput,
} from "./@types/RequestTypes";
import {
  createSearchServiceClient,
  SearchServiceClient,
} from "./graphql/SearchServiceClient";
import {
  EditUserProfileMutation,
  GetCdpUserProfileQuery,
} from "../__generated__/types-member";
import {
  AuthQuery,
  CancelReservationMutation,
  CompleteSignUpProcessMutation,
  Get_Recent_SearchQuery,
  GetHotelAutoCompleteSuggestionsQuery,
  GetHotelAvailabilityQuery,
  GetHotelContactsQuery,
  GetReservationQuery,
  GetUserByEmailQuery,
  Guest,
  HotelQuery,
  HotelsQuery,
  Post_Recent_SearchMutation,
  ResetPassword_SendEmailMutation,
  ResetPasswordMutation,
  SearchHotelsAmenitiesByHotelCodesQuery,
  SearchHotelsBrandsByHotelCodesQuery,
  SearchHotelsByInterestQuery,
  SearchLocations_ProximityQuery,
  SearchLocationsQuery,
  SignUp_WifiMutation,
  SignUpMutation,
} from "../__generated__/types-guest";
import {
  PlacesDetailsQuery,
  PlacesSearchQuery,
} from "../__generated__/types-search";
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ServiceProvider {
  export class Member {
    public client: MemberClient;
    private static instance: Member;
    public static async getInstance(): Promise<Member> {
      if (!Member.instance) {
        Member.instance = new Member();
        Member.instance.initializeClient();
      }
      return Member.instance;
    }
    public async getMemberProfile(): Promise<GetCdpUserProfileQuery> {
      await this.initializeClient();
      return this.client.getMemberProfile();
    }
    public async getUserProfile() {
      await this.initializeClient();
      return this.client.getUserProfile();
    }
    public async getMemberActivityPoint(
      requestInput: IGetProfilePointActivityInput
    ) {
      await this.initializeClient();
      return this.client.getActivityPointProfile(requestInput);
    }
    public async getMemberTripHistory(
      requestInput: IGetMemberTripHistoryInput
    ) {
      await this.initializeClient();
      return this.client.getMemberTripHistory(requestInput);
    }
    public async editUserProfile(
      requestInput: IUpdateUserProfileRequestInput
    ): Promise<EditUserProfileMutation> {
      await this.initializeClient();
      return this.client.editUserProfile(requestInput);
    }
    private static async getMemberClient() {
      const params: IApolloClientProps = {
        apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}/member/graphql`,
      };
      const client = await createMemberClient(params);
      return client;
    }
    private initializeClient = async () => {
      if (!this.client) {
        this.client = await Member.getMemberClient();
      }
    };
  }
  export class Guest {
    public client: GuestClient;
    private static instance: Guest;

    public static async getInstance(): Promise<Guest> {
      if (!Guest.instance) {
        Guest.instance = new Guest();
        await Guest.instance.initializeClient();
      }
      return Guest.instance;
    }
    public async guestlogin(
      username: string,
      password: string
    ): Promise<AuthQuery> {
      await this.initializeClient();
      return this.client.guestLogin(username, password);
    }
    public async sendResetPasswordEmail(
      email: string
    ): Promise<ResetPassword_SendEmailMutation> {
      await this.initializeClient();
      return this.client.sendResetPasswordEmail(email);
    }
    public async resetPassword(
      newPassword: string,
      args: string
    ): Promise<ResetPasswordMutation> {
      await this.initializeClient();
      return this.client.resetPassword(newPassword, args);
    }
    public async checkUserByEmail(email: string): Promise<GetUserByEmailQuery> {
      await this.initializeClient();
      return this.client.checkUserByEmail(email);
    }
    public async signUp(requestInput: ISignUpInput): Promise<SignUpMutation> {
      await this.initializeClient();
      return this.client.signUp(requestInput);
    }
    public async signUpWifi(
      email: string,
      firstName: string,
      lastName: string,
      hotelName: string
    ): Promise<SignUp_WifiMutation> {
      await this.initializeClient();
      return this.client.signUpWifi(email, firstName, lastName, hotelName);
    }
    public async completeSignUpProcess(
      requestInput: ICompleteSignUpProcessRequestInput
    ): Promise<CompleteSignUpProcessMutation> {
      await this.initializeClient();
      return this.client.completeSignUpProcess(requestInput);
    }
    public async getHotelDetails(hotelId: string): Promise<HotelQuery> {
      await this.initializeClient();
      return this.client.getHotelDetails(hotelId);
    }

    public async getHotelAutoCompleteSuggestions(
      partialName: string
    ): Promise<GetHotelAutoCompleteSuggestionsQuery> {
      await this.initializeClient();
      return this.client.getHotelAutoCompleteSuggestions(partialName);
    }
    public async getLocationInterests(
      locationSearchInputUrl: string
    ): Promise<unknown> {
      await this.initializeClient();
      return this.client.getLocationInterests(locationSearchInputUrl);
    }
    public async getHotelAvailability(
      adults: number,
      children: number,
      childrenAges: string,
      crsHotelCode: string,
      chainId: string,
      start: string,
      end: string,
      availReqType: string,
      promotionCode: string,
      ratePlanCode: string,
      ratePlanFilterCode: string,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      abortController: any
    ): Promise<GetHotelAvailabilityQuery> {
      await this.initializeClient();
      return this.client.getHotelAvailability(
        adults,
        children,
        childrenAges,
        crsHotelCode,
        chainId,
        start,
        end,
        availReqType,
        promotionCode,
        ratePlanCode,
        ratePlanFilterCode,
        abortController
      );
    }
    public async getHotelByInterest(
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      distance: any,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      latitude: any,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      longitude: any,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      url: any
    ): Promise<SearchHotelsByInterestQuery> {
      await this.initializeClient();
      return this.client.getHotelByInterest(distance, latitude, longitude, url);
    }
    public async getHotelProximity(
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      distance: any,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      latitude: any,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      longitude: any,
      options?: {
        type?: any;
        cityName?: any;
      }
    ): Promise<HotelsQuery> {
      await this.initializeClient();
      if (options && options.type && options.cityName) {
        return this.client.getHotelProximity(distance, latitude, longitude, {
          type: options?.type,
          cityName: options?.cityName,
        });
      }
      return this.client.getHotelProximity(distance, latitude, longitude);
    }
    public async getLocationProximity(
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      distance: any,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      latitude: any,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      longitude: any
    ): Promise<SearchLocations_ProximityQuery> {
      await this.initializeClient();
      return this.client.getLocationProximity(distance, latitude, longitude);
    }
    public async brandFilterHotels(
      hotelCodes: string
    ): Promise<SearchHotelsBrandsByHotelCodesQuery> {
      await this.initializeClient();
      return this.client.brandFilterHotels(hotelCodes);
    }
    public async searchHotelsAmenities(
      hotelCodes: string
    ): Promise<SearchHotelsAmenitiesByHotelCodesQuery> {
      await this.initializeClient();
      return this.client.searchHotelsAmenities(hotelCodes);
    }
    public async cancelReservation(
      confirmationNumber: string
    ): Promise<CancelReservationMutation> {
      await this.initializeClient();
      return this.client.cancelReservation(confirmationNumber);
    }
    public async getReservationDetails(
      confirmationNumber: string,
      surname: string
    ): Promise<GetReservationQuery> {
      await this.initializeClient();
      return this.client.getReservationDetails(confirmationNumber, surname);
    }
    public async getRecentSearch(
      memberId: string
    ): Promise<Get_Recent_SearchQuery> {
      await this.initializeClient();
      return this.client.getRecentSearch(memberId);
    }
    public async postRecentSearch(
      memberID: string,
      startDate: string,
      hotelID: string,
      endDate: string,
      roomType: string,
      promoCode: string,
      occupancy: string,
      groupCode: string,
      ratePlanFilterCode: string,
      lastUpdated: string
    ): Promise<Post_Recent_SearchMutation> {
      await this.initializeClient();
      return this.client.postRecentSearch(
        memberID,
        startDate,
        hotelID,
        endDate,
        roomType,
        promoCode,
        occupancy,
        groupCode,
        ratePlanFilterCode,
        lastUpdated
      );
    }
    public static async getGuestClient(): Promise<GuestClient> {
      const params = {
        apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}/guest/graphql`,
      };
      const client = await createGuestClient(params);
      return client;
    }
    private initializeClient = async () => {
      if (!this.client) {
        this.client = await Guest.getGuestClient();
      }
    };
  }
  export class SearchService {
    public client: SearchServiceClient;
    private static instance: SearchService;

    public static async getInstance(): Promise<SearchService> {
      if (!SearchService.instance) {
        SearchService.instance = new SearchService();
        await SearchService.instance.initializeClient();
      }
      return SearchService.instance;
    }
    public async getPlaces(
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      searchInput: any,
      sessionToken: string,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      abortController: any
    ): Promise<PlacesSearchQuery> {
      await this.initializeClient();
      return this.client.getPlaces(searchInput, sessionToken, abortController);
    }
    public async getPlaceDetails(
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      placeId: any,
      sessionToken: string,
      placeType: string,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      abortController: any
    ): Promise<PlacesDetailsQuery> {
      await this.initializeClient();
      return this.client.getPlaceDetails(
        placeId,
        sessionToken,
        placeType,
        abortController
      );
    }

    private static async getSearchServiceClient() {
      const params: IApolloClientProps = {
        apiEndpoint: `${process.env.GATSBY_CDP_AUTH0_AUDIENCE}/searchservice/graphql`,
      };
      const client = await createSearchServiceClient(params);
      return client;
    }

    private initializeClient = async () => {
      if (!this.client) {
        this.client = await SearchService.getSearchServiceClient();
      }
    };
  }
}

export const memberServiceProvider = async (): Promise<any> => {
  const memberInstance = await ServiceProvider.Member.getInstance();
  return memberInstance;
};

export const guestServiceProvider = async (): Promise<any> => {
  const guestInstance = await ServiceProvider.Guest.getInstance();
  return guestInstance;
};

export const searchServiceProvider = async (): Promise<any> => {
  const searchServiceInstance =
    await ServiceProvider.SearchService.getInstance();
  return searchServiceInstance;
};
